import React from "react"

// Images
import Arrow from "./images/arrow.svg"

class ArticleFeatureGallery extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      active: 1,
      authorText: this.props.images[0].author,
      typing: false
    }

    this.animating = false
    this.animatingTimeout = null
  }

  componentDidMount() {
    this.images = this.props.images
    this.imageCount = Object.keys(this.images).length
  }

  componentWillUnmount() {
    if (this.animatingTimeout) clearTimeout(this.animatingTimeout)
  }

  startAnimation = () => {
    if (this.animatingTimeout) clearTimeout(this.animatingTimeout)

    this.animating = true
    this.animatingTimeout = setTimeout(this.endAnimation, 800)
  }

  endAnimation = () => {
    if (this.animatingTimeout) clearTimeout(this.animatingTimeout)

    this.animating = false
  }

  prevSlide = () => {
    if (this.state.active === 1 || this.animating) return

    this.startAnimation()

    this.setState({
      active: this.state.active - 1
    }, this.updateAuthor)

    this.props.updateActive(this.state.active - 1)
  }

  nextSlide = () => {
    if (this.state.active === this.imageCount || this.animating) return

    this.startAnimation()

    this.setState({
      active: this.state.active + 1
    }, this.updateAuthor)

    this.props.updateActive(this.state.active + 1)
  }

  updateAuthor = () => {
    if (this.writingInterval) clearInterval(this.writingInterval)

    this.newAuthor = this.images[this.state.active - 1].author
    this.authorLength = this.newAuthor.length

    this.currentChar = 0

    // Reset author span
    this.setState({
      authorText: "",
      typing: true
    })

    this.writingInterval = setInterval(this.addLetter, 50)
  }

  addLetter = () => {
    if (this.currentChar ===  this.authorLength) {
      clearInterval(this.writingInterval)

      this.setState({
        typing: false
      })
      return
    }

    this.currentChar++

    this.setState({
      authorText: this.newAuthor.substring(0, this.currentChar)
    })
  }

  render() {
    const {images} = this.props,
          {gallerySpin} = this.props

    return (
      <div className="article__feature-gallery">

        <div className="article__feature-gallery-images">
          <ul>
            {Object.keys(images).map((index) => {
              const x = parseInt(index),
                    src = images[x].url.publicURL,
                    author = images[x].author

              let link = null

              if (gallerySpin) {
                link = images[x].fullSizeUrl.publicURL
              }

              let active = (this.state.active - 1 === x),
                  before = (this.state.active - 1 > x),
                  translate = (active ? "0%" : (
                    before ?
                    "-100%" :
                    "100%"
                  )),
                  zIndex = (active ? 2 : 1)

              let styles = {
                transform: `translate3d(${translate}, 0, 0)`,
                zIndex: zIndex
              }

              return (
                <li key={x} style={styles}>
                  {gallerySpin ? (
                    <a href={link} target="_blank" rel="noopener noreferrer">
                      <img src={src} width="1360" height="760" alt={author} onClick={!gallerySpin ? this.props.openPopup : undefined} />
                    </a>
                    ) : (
                    <img src={src} width="1360" height="760" alt={author} onClick={!gallerySpin ? this.props.openPopup : undefined} />
                    )
                  }
                </li>
              )
            })}
          </ul>
        </div>

        <div className="article__feature-gallery-bottom">
          <div className="article__feature-gallery-authors" data-typing={this.state.typing}>
            <span ref={authorSpan => (this.authorSpan = authorSpan)}>{this.state.authorText}</span>
          </div>
          <div className="article__feature-gallery-nav">
            <div onClick={this.prevSlide}>
              <img src={Arrow} width="14" height="10" alt="Previous Slide" />
            </div>
            <div>
              {this.state.active}/{this.imageCount}
            </div>
            <div onClick={this.nextSlide}>
              <img src={Arrow} width="14" height="10" alt="Next Slide" />
            </div>
          </div>
        </div>

      </div>
    )
  }
}

export default ArticleFeatureGallery