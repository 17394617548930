import React from "react"
import Consumer from "../layouts/Context"
import 'ssr-intersection-observer'
import { graphql } from "gatsby"
import { TransitionState } from "gatsby-plugin-transition-link"
import { Draggable } from "gsap/all"
import { InView } from 'react-intersection-observer'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

// Components
import SEO from "../components/seo"
import ArticleWipe from "../components/ArticleWipe/ArticleWipe"
import ArticleHeader from "../components/ArticleHeader/ArticleHeader"
import ArticleHeaderBtn from "../components/ArticleHeaderBtn/ArticleHeaderBtn"
import ArticleShare from "../components/ArticleShare/ArticleShare"
import ArticleFeatureGallery from "../components/ArticleFeatureGallery/ArticleFeatureGallery"
import ArticleFooter from "../components/ArticleFooter/ArticleFooter"
import ArticleBio from "../components/ArticleBio/ArticleBio"
import ArticleBottom from "../components/ArticleBottom/ArticleBottom"

class FeatureGallery extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      active: 1,
      fixed: true,
      popupOpen: false
    }

    this.clickStart = 0
    this.rotating = false
  }

  componentDidMount() {
    Draggable.create(this.galleryImage, {type: 'rotation', throwProps: true})
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks()
  }

  setActiveImage = (x) => {
    this.setState({
      active: x
    })
  }

  closePopup = () => {
    this.setState({
      popupOpen: false
    }, () => {
      enableBodyScroll()
    })
  }

  openPopup = () => {
    this.setState({
      popupOpen: true
    }, () => {
      disableBodyScroll()
    })
  }

  bottomInView = (inView, entry) => {
    this.setState({
      fixed: !inView
    })
  }

  contentInView = (inView, entry) => {
    // console.log(entry)
  }

  render() {
    const post = this.props.data.markdownRemark,
          seoDescription = post.frontmatter.seoDescription,
          seoImage = post.frontmatter.seoImage,
          bgModifier = post.frontmatter.bgModifier,
          gallerySpin = post.frontmatter.gallerySpin,
          title = post.frontmatter.title,
          subtitle = post.frontmatter.subtitle,
          author = post.frontmatter.author,
          authorAvatar = post.frontmatter.authorAvatar,
          authorBio = post.frontmatter.authorBio,
          htmlContent = post.html,
          galleryItems = post.frontmatter.gallery

    return (
      <TransitionState>
        {({ transitionStatus }) => {
          return (
            <>
              <SEO
                title={title}
                description={seoDescription ? seoDescription : post.excerpt}
                image={seoImage}
              />

              <Consumer>
                {context =>
                  <ArticleWipe context={context} />
                }
              </Consumer>

              <div className="page-wipe">
                <div className="page-wipe__panel page-wipe__panel--article" ref={pageWipe => (this.pageWipePanel = pageWipe)} />
              </div>

              {!gallerySpin && (

                <div
                  className="article__feature-popup"
                  data-open={this.state.popupOpen}
                  onClick={this.closePopup}
                >
                  <div className="article__feature-popup-btn" onClick={this.closePopup}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M1 31L31 1" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="2" fillRule="evenodd" /><path d="M31 31L1 1" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="2" fillRule="evenodd" /></svg>
                  </div>
                  <div className="article__feature-popup-inner">
                    <img
                      src={galleryItems[this.state.active - 1].url.publicURL}
                      width="1360"
                      height="760"
                      alt={galleryItems[this.state.active - 1].author}
                      ref={galleryImage => (this.galleryImage = galleryImage)}
                    />
                  </div>
                </div>

              )}

              <div className="article" data-bg={bgModifier}>
                <div className="article__inner">
                  <div className="container">

                    <ArticleHeaderBtn pageWipe={this.pageWipePanel} />
                    <ArticleHeader modifier="feature-gallery" title={title} subtitle={subtitle} authorPrefix="by" author={author} />

                    <div className="cols flex">
                      <ArticleShare modifier="text" col={`m12 t1`} />
                      <div className="article__feature-gallery-col col m12">
                        <ArticleFeatureGallery gallerySpin={gallerySpin} openPopup={this.openPopup} updateActive={this.setActiveImage} images={galleryItems} />
                      </div>
                      <div className="col m12 t10 d8 dl6">
                        <div className="article__container">
                          <InView
                            onChange={this.contentInView}
                            threshold={0}
                          >
                            {({ inView, ref }) => (
                              <div
                                className="article__content"
                                dangerouslySetInnerHTML={{ __html: htmlContent }}
                                ref={ref}
                              />
                            )}
                          </InView>
                        </div>
                      </div>
                    </div>

                    <ArticleFooter fixed={this.state.fixed} />
                  </div>
                </div>

                { authorBio && authorAvatar && (
                  <ArticleBio author={author} authorAvatar={authorAvatar} authorBio={authorBio} />
                )}

                <InView
                  onChange={this.bottomInView}
                  threshold={0}
                >
                  {({ inView, ref }) => (
                    <div className="article__bottom" ref={ref} data-anim={inView}>
                      <ArticleBottom pageWipe={this.pageWipePanel} />
                    </div>
                  )}
                </InView>

              </div>
            </>
          )
        }}
      </TransitionState>
    )
  }
}

export default FeatureGallery

export const pageQuery = graphql`
  query FeatureGalleryBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        seoDescription
        seoImage
        chapter
        bgModifier
        gallerySpin
        title
        subtitle
        author
        authorAvatar {
          publicURL
        }
        authorBio
        gallery {
          url {
            publicURL
          }
          fullSizeUrl {
            publicURL
          }
          author
        }
      }
    }
  }
`